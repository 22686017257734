.button {
  background: #000;
  color: #fff;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin: auto 0;
}

.whiteButton {
  background: #fff;
  color: #000;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 300;
  border: 1px solid #000;
  border-radius: 2px;
  cursor: pointer;
  margin: auto 0;
}

body {
  padding-top: 40px;
}

.container {
  margin-bottom: 100px;
  margin-left: 5%;
  margin-right: 5%;
}

.content {
  display: block;
  width: 85%;
  margin: auto;
}

.header {
  text-align: center;
  font-weight: lighter;
  font-size: 1.9em;
  margin-bottom: 1%;
}
.pager {
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: sticky;
  top: 0;
  right: 0;
  padding: 40px;
  z-index: 999;
  text-align: right;
}
.titleContainer {
  margin: 50px 0;
  display: flex;
  font-weight: bold;
}

.title {
  font-size: 1.2em;
  margin-right: 20%;
}

.buttonGroup {
  display: flex;
  margin-top: 5%;
}

.cancelButton {
  margin-left: 30px;
  cursor: pointer;
  color: rgb(35, 117, 194);
}

.fieldContainer {
  margin: 20px 0;
  width: 45%;
  display: flex;
  justify-content: space-evenly;
}

.fieldTitle {
  font-weight: bold;
  margin: 10px 0;
}

.inputField {
  width: 50%;
  line-height: 2.5;
  border: 1px solid #000;
  color: #000;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 1.1em;
  background-color: transparent;
}

.separator {
  margin-top: 10%;
  margin-left: -10%;
  margin-right: 50px;
  font-size: 1.3em;
}

.resultForm {
  width: 36%;
  margin: 2% 0;
  margin-right: 4%;
  margin-bottom: 0;
  height: 350px;
  font-size: 1.5em;
  padding: 10px;
  border: 1px solid grey;
  background: #f0efed;
  overflow: auto;
}

.resultName {
  font-weight: lighter;
  font-size: 0.6em;
  padding-top: 10px;
  margin-left: 15px;
}

.loadMore {
  font-weight: lighter;
  font-size: 0.6em;
  padding-top: 10px;
  margin-left: 15px;
  cursor: pointer;
  color: #2375c2;
}
.imageStatistic {
  font-weight: 400;
}

.loadingIcon {
  margin-left: 10px;
  width: 50px;
  height: 50px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.closeIcon {
  position: absolute;
  right: 15px;
  top: 15px;
  background-image: url(/img/close.png);
  width: 30px;
  height: 30px;
  background-repeat: round;
  opacity: 0.3;
  cursor: pointer;
}

.upload {
  background: #000;
  color: #fff;
  padding: 0 20px;
  font-size: 1em;
  line-height: 50px;
  font-weight: 300;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  border-radius: 2px;
  height: 50px;
}

.singleRow {
  display: flex;
}

.singleRowContainer {
  display: block;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px #eaeaea solid;
  font-size: 14px;
}

.batchTitle {
  width: 40%;
  margin-left: 5px;
}

.batchContent {
  width: 30%;
}

.rowCheck {
  width: 15px;
  margin-right: 20px;
}

.modalHeader {
  font-weight: lighter;
  font-size: 1.8em;
  margin-bottom: 40px;
  margin-top: 10px;
}

.modalBtnContainer {
  margin-top: 20px;
  display: flex;
}

.batchHeaderTitle {
  width: 40%;
  margin-left: 5px;
  font-weight: bold;
}
.batchHeaderContent {
  width: 30%;
  font-weight: bold;
}

.historyRoot {
  position: relative;
}

.historyLoaderBG {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.historyLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
}

.divider {
  margin-top: 40px;
  margin-bottom: 40px;
}

.modalLoader {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.1;
}
